<template>
	<div class="box">
		<div class="content">
			<el-tabs v-model="type" @tab-click="tabNav">
				<!-- 全部订单 -->
				<el-tab-pane label="代发商品" name="0">
					<template>
						<el-table ref="multipleTable" class="table-box" :data="cartList.valid" style="width: 100%" @selection-change="handleSelectionChange">
							<el-table-column type="selection" width="55"></el-table-column>
							<el-table-column label="商品" width="460">
								<template slot-scope="scope">
									<div class="goodsContent">
										<img :src="scope.row.productInfo.attrInfo.image" v-if="scope.row.productInfo.attrInfo" />
										<img :src="scope.row.productInfo.image" v-else />
										<div>
											<div class="name">{{ scope.row.productInfo.store_name }}</div>
											<div v-if="scope.row.productInfo.attrInfo">{{ scope.row.productInfo.attrInfo.suk }}</div>
										</div>
									</div>
								</template>
							</el-table-column>
							<el-table-column align="center" prop="truePrice" label="规格价"></el-table-column>
							<el-table-column align="center" label="规格数量">
								<template slot-scope="scope">
									<el-input-number
										size="mini"
										v-model="scope.row.cart_num"
										@change="cart_numChange(scope.row.id, scope.row.cart_num)"
										:min="1"
										:max="scope.row.productInfo.attrInfo ? scope.row.productInfo.attrInfo.stock : scope.row.productInfo.stock"
									></el-input-number>
									<div class="stock">
										仅剩{{ scope.row.productInfo.attrInfo ? scope.row.productInfo.attrInfo.stock : scope.row.productInfo.stock
										}}{{ scope.row.productInfo.unit_name }}
									</div>
								</template>
							</el-table-column>
							<el-table-column align="center" label="金额">
								<template slot-scope="scope">
									<div>{{ Number(scope.row.cart_num) * Number(scope.row.truePrice) }}</div>
								</template>
							</el-table-column>
							<el-table-column align="center" label="操作">
								<template slot-scope="scope">
									<el-button size="mini" type="danger" @click="delgoods(scope.$index, scope.row.id)">删除</el-button>
								</template>
							</el-table-column>
						</el-table>
						<div class="allCheck">
							<div class="left">
								<el-checkbox v-model="checkAll" @change="toggleSelection(cartList.valid)">全选</el-checkbox>
								<div class="del">删除选中商品</div>
							</div>
							<div class="right">
								<div>
									已选中
									<span>{{ defaultNum }} 件</span>
								</div>
								<div>
									商品总额
									<span>￥{{ countmoney }}</span>
								</div>
								<div :class="[isActive ? activeClass : '', errorClass]" @click="paymentTap">结算</div>
							</div>
						</div>
					</template>
				</el-tab-pane>
				<!-- 待付款 -->
				<el-tab-pane label="批量导入" name="1"><div>批量导入</div></el-tab-pane>
			</el-tabs>
		</div>
	</div>
</template>

<script>
// changeCartNum
import { getCartList, postCartDel, changeCartNum } from '@api/user';
import { mul, add } from '@api/order';
import { mapActions } from 'vuex';
export default {
	name: 'ShoppingCart',
	components: {
		// OrderList
	},
	data() {
		return {
			type: 0,
			cartList: {},
			multipleSelection: [],
			countmoney: 0, //总价格
			defaultNum: 0,
			checkAll: false,
			isActive: false,
			activeClass: 'active',
			errorClass: 'settlement'
		};
	},
	mounted() {
		let that = this;
		that.getCartList();
	},
	methods: {
		...mapActions(['aGetCartNum']),
		// tab跳转
		// tabNav() {
		// 	this.queryParam.page = 1;
		// },
		getCartList: function() {
			let that = this;
			getCartList().then(res => {
				that.cartList = res.data;
			});
		},
		// 删除商品；
		delgoods(index, id) {
			let that = this;
			postCartDel(id).then(function() {
				that.$dialog.toast({
					mes: '从进货单删除商品成功',
					callback: () => {
						that.cartList.valid.splice(index, 1);
						that.$store.dispatch('aGetCartNum'); // 更新购物车数量
					}
				});
			});
		},
		// 多选改变的时候
		handleSelectionChange(val) {
			let that = this;
			that.multipleSelection = val;
			if (that.multipleSelection.length != 0) {
				that.isActive = true;
			} else {
				that.isActive = false;
			}
			if (that.multipleSelection.length == that.cartList.valid.length) {
				that.checkAll = true;
				that.countMoney();
				that.defaultNum = that.multipleSelection.length;
			} else {
				that.checkAll = false;
				that.countMoney();
				that.defaultNum = that.multipleSelection.length;
			}
		},
		toggleSelection(rows) {
			let that = this;
			rows.forEach(row => {
				that.$refs.multipleTable.toggleRowSelection(row);
			});
		},
		cart_numChange: function(id, cart_num) {
			changeCartNum(id, Math.max(cart_num, 1) || 1);
			this.countMoney();
		},
		//总共价钱；
		countMoney: function() {
			let that = this;
			let carmoney = 0;
			that.multipleSelection.forEach(item => {
				carmoney = add(carmoney, mul(item.cart_num, item.truePrice));
			});
			that.countmoney = carmoney;
		},
		paymentTap: function() {
			var that = this;
			// that.$router.push({ path: '/payment', query: order })
			that.$router.push({ path: '/payment'})
		}
	}
};
</script>

<style lang="less" scoped>
.box {
	background: #fff;
	.content {
		width: 1232px;
		margin: 10px auto;
		padding: 26px 0;
		display: flex;
		.el-tabs {
			flex: 1;
			.table-box {
				.goodsContent {
					display: flex;
					align-items: flex-start;
					img {
						width: 100px;
						height: 100px;
						margin: 0 12px;
					}
					div {
						text-align: left;
					}
				}
				.stock {
					line-height: 30px;
					color: #f65b5b;
					font-size: 12px;
				}
			}
			.allCheck {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-top: 30px;
				font-size: 14px;
				.left {
					display: flex;
					align-items: center;
					.del {
						margin-left: 20px;
					}
					.del:hover {
						color: #ff6600;
						cursor: pointer;
					}
				}
				.right {
					display: flex;
					align-items: center;
					div {
						margin-left: 30px;
						span {
							color: #ff6600;
							font-size: 24px;
							font-weight: 700;
						}
					}
					div.settlement {
						text-align: center;
						line-height: 62px;
						width: 200px;
						background: #999;
						color: #fff;
						font-size: 20px;
						cursor: pointer;
					}
					div.active {
						background: #f60;
					}
				}
			}
		}
	}
}
</style>
