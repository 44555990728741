import {
	cancelOrder
} from "@api/user"; // takeOrder, delOrder, payOrder
import dialog from "@utils/dialog";
// import { pay } from "@libs/wechat";

export function cancelOrderHandle(orderId) {
	return new Promise((resolve, reject) => {
		dialog.confirm({
			mes: "确认取消该订单?",
			opts() {
				cancelOrder(orderId)
					.then(res => {
						dialog.success("取消成功");
						resolve(res);
					})
					.catch(err => {
						dialog.error("取消失败");
						reject(err);
					});
			}
		});
	});
}

// export function takeOrderHandle(orderId) {
//   return new Promise((resolve, reject) => {
//     takeOrder(orderId)
//       .then(res => {
//         resolve(res);
//       })
//       .catch(err => {
//         dialog.error("收货失败");
//         reject(err);
//       });
//   });
// }

// export function delOrderHandle(orderId) {
//   return new Promise((resolve, reject) => {
//     dialog.confirm({
//       mes: "确认删除该订单?",
//       opts() {
//         delOrder(orderId)
//           .then(res => {
//             dialog.success("删除成功");
//             resolve(res);
//           })
//           .catch(err => {
//             dialog.error("删除失败");
//             reject(err);
//           });
//       }
//     });
//   });
// }

// export function payOrderHandle(orderId, type, from) {
//   return new Promise((resolve, reject) => {
//     dialog.loading.open("");
//     payOrder(orderId, type, from)
//       .then(res => {
//         const data = res.data;
//         dialog.loading.close();
//         switch (data.status) {
//           case "WECHAT_H5_PAY":
//             reject(data);
//             setTimeout(() => {
//               location.replace(data.result.jsConfig.mweb_url);
//             }, 100);
//             break;
//           case "ORDER_EXIST":
//           case "EXTEND_ORDER":
//           case "PAY_ERROR":
//           case "PAY_DEFICIENCY":
//             dialog.toast({ mes: res.msg });
//             reject(data);
//             break;
//           case "SUCCESS":
//             dialog.success(res.msg);
//             resolve(data);
//             break;
//           case "WECHAT_PAY":
//             pay(data.result.jsConfig).then(() => {
//               resolve(data);
//             });
//         }
//       })
//       .catch(err => {
//         dialog.loading.close();
//         dialog.toast({ mes: err.msg || "订单支付失败" });
//       });
//   });
// }
export function div(arg1, arg2) {
	var t1 = 0,
		t2 = 0,
		r1,
		r2;
	try {
		t1 = arg1.toString().split(".")[1].length;
	} catch (e) {
		t1 = 0;
	}
	try {
		t2 = arg2.toString().split(".")[1].length;
	} catch (e) {
		t2 = 0;
	}
	r1 = Number(arg1.toString().replace(".", ""));
	r2 = Number(arg2.toString().replace(".", ""));
	return mul(r1 / r2, Math.pow(10, t2 - t1));
}
//乘法函数，用来得到精确的乘法结果
//说明：javascript的乘法结果会有误差，在两个浮点数相乘的时候会比较明显。这个函数返回较为精确的乘法结果。
//调用：mul(arg1,arg2)
//返回值：arg1乘以arg2的精确结果
export function mul(arg1, arg2) {
	var m = 0,
		s1 = arg1.toString(),
		s2 = arg2.toString();
	try {
		m += s1.split(".")[1].length;
	} catch (e) {
		m = 0;
	}
	try {
		m += s2.split(".")[1].length;
	} catch (e) {
		m = m || 0;
	}
	return (
		(Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
		Math.pow(10, m)
	);
}

//加法函数，用来得到精确的加法结果
//说明：javascript的加法结果会有误差，在两个浮点数相加的时候会比较明显。这个函数返回较为精确的加法结果。
//调用：add(arg1,arg2)
//返回值：arg1加上arg2的精确结果
export function add(arg1, arg2) {
	var r1, r2, m, n;
	try {
		r1 = arg1.toString().split(".")[1].length;
	} catch (e) {
		r1 = 0;
	}
	try {
		r2 = arg2.toString().split(".")[1].length;
	} catch (e) {
		r2 = 0;
	}
	m = Math.pow(10, Math.max(r1, r2));
	n = r1 >= r2 ? r1 : r2;
	return ((arg1 * m + arg2 * m) / m).toFixed(n);
}

//减法函数，用来得到精确的减法结果
//说明：javascript的加法结果会有误差，在两个浮点数相加的时候会比较明显。这个函数返回较为精确的减法结果。
//调用：sub(arg1,arg2)
//返回值：arg1减去arg2的精确结果
export function sub(arg1, arg2) {
	var r1, r2, m, n;
	try {
		r1 = arg1.toString().split(".")[1].length;
	} catch (e) {
		r1 = 0;
	}
	try {
		r2 = arg2.toString().split(".")[1].length;
	} catch (e) {
		r2 = 0;
	}
	m = Math.pow(10, Math.max(r1, r2));
	//动态控制精度长度
	n = r1 >= r2 ? r1 : r2;
	return ((arg1 * m - arg2 * m) / m).toFixed(n);
}

function Compute(value) {
	this.value = value;
}
Object.assign(Compute.prototype, {
	add(v) {
		this.value = add(this.value, v);
		return this;
	},
	sub(v) {
		this.value = sub(this.value, v);
		return this;
	},
	div(v) {
		this.value = div(this.value, v);
		return this;
	},
	mul(v) {
		this.value = mul(this.value, v);
		return this;
	}
});

export default function(value) {
	return new Compute(value);
}
